import { OnboardingStatus } from './OnboardingStatus';
import { BackfillStatus } from './BackfillStatus';
import { TeamCategory } from './TeamCategory';

export class Onboarding {
    public teamName: string;
    public latestPoOnboardTime?: number;
    public latestInvoiceOnboardTime?: number;
    public coupaRuleOnboardTime?: number;
    public lastModifiedTime: number;
    protected coupaRuleActivated: string;
    public onboardingStatus: OnboardingStatus;
    public poBackfillStatus?: BackfillStatus;
    public invoiceBackfillStatus?: BackfillStatus;
    public owner: string;
    public teamCategory: TeamCategory;
    public creationTime: number;

    constructor() {
        this.teamName = '';
        this.lastModifiedTime = Date.now();
        this.coupaRuleActivated = 'N';
    }

    public get isCoupaRuleActivated(): boolean {
        return this.coupaRuleActivated === 'Y';
    }

    public set isCoupaRuleActivated(value: boolean) {
        this.coupaRuleActivated = value ? 'Y' : 'N';
    }
}
