export enum ReceiptStatus {
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    PENDING_POST = 'PENDING_POST',
    POSTED = 'POSTED',
    POST_FAILURE = 'POST_FAILURE',
    PENDING_VOID = 'PENDING_VOID',
    VOIDED = 'VOIDED',
    VOID_FAILURE = 'VOID_FAILURE',
    ABANDONED = 'ABANDONED',
}

export const ReceiptStatusesConsumingLineAmount = [
    ReceiptStatus.POSTED,
    ReceiptStatus.PENDING_POST,
    ReceiptStatus.PENDING_VOID,
    ReceiptStatus.POST_FAILURE,
    ReceiptStatus.VOID_FAILURE,
    ReceiptStatus.ABANDONED,
];
