export class ReceiptPublishingEvent {
    public receiptNumber = '';
    public timestamp = Date.now();
    public lineId = '';
    public receiptId = '';
    public lineNumber = '';
    public purchaseId = '';
    public retryCount = 0;
    public status = '';
    public TTL = 0;
    public executionId = '';

    constructor() {
        const ttl = new Date();
        ttl.setMonth(ttl.getMonth() + 6);
        this.TTL = Math.floor(ttl.getTime() / 1000);
    }

    public static from(obj: unknown): ReceiptPublishingEvent {
        return Object.assign(new ReceiptPublishingEvent(), obj);
    }
}