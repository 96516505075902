export enum CoupaPurchaseLineStatus {
    RECEIVED = 'received',
    CLOSED = 'closed',
    CANCELLED = 'cancelled',
    SOFT_CLOSED_FOR_INVOICING = 'soft_closed_for_invoicing',
    SOFT_CLOSED_FOR_RECEIVING = 'soft_closed_for_receiving',
    CREATED = 'created',
    DRAFT = 'draft',
    PARTIALLY_RECEIVED = 'partially_received',
}
