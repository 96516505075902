export interface GetAccountingStringRequest {
    teamNames: string[];
    pageSize?: number;
    from?: number;
}

export interface AccountingStringMatchRequest {
    costCenter: string;
    account: string;
    company?: string;
    location?: string;
    channel?: string;
    project?: string;
    product?: string;
    customSegment?: string;
}

export interface AccountingStringFilterRequest extends GetAccountingStringRequest {
    combination: Combination;
    company?: string;
    costCenter?: string;
    account?: string;
    location?: string;
    channel?: string;
    project?: string;
    product?: string;
    customSegment?: string;
}

export enum Combination {
    AND = 'and',
    OR = 'or',
}
