import { MspModel } from '../MspModel';

export class InvoiceLine extends MspModel {
    public invoiceId: string;
    public ofaId: string;
    public lineNumber: number;
    public purchaseId: string;
    public poNumber: string;
    public purchaseLineId: number;
    public purchaseLineNumber: number;
    public amount: number;
    public unitPrice: number;
    public quantityInvoiced: number;
    public poLineQuantity: number;
    public allocatedAmount: number;
    public status?: string;
    public invoiceStatus?: string;
    public invoiceTotal?: number;
    public servicePeriodStartDate: number;
    public servicePeriodEndDate: number;
    public createdAtFns?: number;
    public invoiceNumber?: string;
    public isCredit: string;
    public dueDate?: string;

    constructor() {
        super();

        this.ofaId = '';
        this.invoiceId = '';
        this.lineNumber = 0;
        this.purchaseId = '';
        this.purchaseLineNumber = 0;
        this.purchaseLineId = 0;
        this.poNumber = '';
        this.amount = 0;
        this.unitPrice = 0;
        this.allocatedAmount = 0;
        this.quantityInvoiced = 0;
        this.servicePeriodStartDate = 0;
        this.servicePeriodEndDate = 0;
        this.isCredit = 'N';
    }

    public static from(obj: unknown): InvoiceLine {
        return Object.assign(new InvoiceLine(), obj);
    }

    public get credit(): boolean {
        return this.isCredit === 'Y';
    }

    public set credit(value: boolean) {
        this.isCredit = value ? 'Y' : 'N';
    }
}
