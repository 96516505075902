import { MspModel } from '../MspModel';

export interface ChannelTypeDetail {
    [key: string]: number;
}

export class AccountingString extends MspModel {
    company: string;
    location: string;
    costCenter: string;
    account: string;
    product: string;
    channel: string;
    project: string;
    customSegment: string;
    bigObjective?: string;
    channelTypes?: ChannelTypeDetail;
    smartE?: string;
    budgetPlanner?: string;
    planningCurrency?: string;
    version: number;
    teamId: string;
    teamName: string;

    constructor() {
        super();
        this.version = this.updatedAt;
    }
}
