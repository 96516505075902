import BigNumber from 'bignumber.js';

class AspRounder {
    private static readonly DECIMAL_PLACES = 6;
    private static readonly ROUNDING_MODE = BigNumber.ROUND_HALF_UP;

    /**
     * Rounds a big number to the specified decimal places using the specified rounding mode.
     * @param bigNumber The number to round.
     * @param decimalPlaces The decimal places or 6 by default.
     * @param roundingMode The rounding mode or ROUND_HALF_UP by default.
     * @returns The rounded number.
     */
    roundToFixed(bigNumber: BigNumber, decimalPlaces?: number, roundingMode?: BigNumber.RoundingMode) {
        const str = bigNumber.toFixed(
            decimalPlaces ?? AspRounder.DECIMAL_PLACES,
            roundingMode ?? AspRounder.ROUNDING_MODE
        );
        return parseFloat(str);
    }
}

export const Rounder = new AspRounder();
