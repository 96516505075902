import { InvoiceAllocation } from '../invoice';
import { ChannelAllocation } from '../channelAllocation';

export class ReceiptVersion {
    public purchaseIdLineVersionLineNumber: string;
    public receiptNumber: string;
    public purchaseId: string;
    public receiptStatus: string;
    public dueMonth?: string;
    public receiptVersion: number;
    public lineNumber: number;
    public versionCategory?: string;
    public versionDescription?: string;
    public originalReceiptAmount: number;
    public modifiedReceiptAmount: number;
    public originalReceiptTime: number;
    public modifiedReceiptTime: number;
    public originalBilledAmount?: number;
    public modifiedBilledAmount?: number;
    public confirmStatus?: number;
    public receiptId?: string;
    public lineId?: number;
    public creationTime: number;
    public lastModifiedTime?: number;
    public originalChannelAllocations?: ChannelAllocation[];
    public modifiedChannelAllocations?: ChannelAllocation[];
    public originalInvoiceAllocations?: InvoiceAllocation[];
    public modifiedInvoiceAllocations?: InvoiceAllocation[];

    constructor() {
        this.purchaseIdLineVersionLineNumber = '';
        this.receiptNumber = '';
        this.purchaseId = '';
        this.receiptStatus = '';
        this.dueMonth = '';
        this.receiptVersion = 0;
        this.lineNumber = 0;
        this.originalReceiptAmount = 0;
        this.modifiedReceiptAmount = 0;
        this.originalReceiptTime = 0;
        this.modifiedReceiptTime = 0;
        this.creationTime = 0;
    }
}
