import { ChannelAllocation } from '../channelAllocation';
import { PurchaseStatus } from '../enums/PurchaseStatus';
import { InvoiceLine } from '../invoice/InvoiceLine';
import { Receipt } from '../receipt/Receipt';
import { PurchaseLineIndex } from './PurchaseLineIndex';

export class PurchaseIndex {
    public purchaseId: string;
    public confirmStatus?: string;
    public confirmStatusInt?: number;
    public requestAmount: number;
    public currencyCode?: string;
    public orderId?: string;
    public orderNumber?: string;
    public status: PurchaseStatus;
    public statusKeyword?: string;
    public statusDescription?: string;
    public requestCreationTime?: number;
    public orderCreationTime?: number;
    public requesterLogin: string;
    public requesterFirstName?: string;
    public requesterLastName?: string;
    public addressLocationCode?: string;
    public justification?: string;
    public creatorLogin: string;
    public creatorFirstName?: string;
    public creatorLastName?: string;
    public creationTime?: number;
    public lastModifiedTime?: number;
    public location?: string;
    public costCenter?: string;
    public orgName?: string;
    public companyCode?: string;
    public countryCode?: string;
    public mspOwner?: string[];
    public creatorGroupPurchaseType: string;
    public purchaseType: string;
    public supplierId?: string;
    public supplierDisplayName: string;
    public bigObjective?: string;
    public purchaseLines?: PurchaseLineIndex[];
    public invoiceLines?: Map<number, InvoiceLine[]>; // invoiceline number: invoice lines
    public receipts?: Map<number, Receipt[]>;
    public channelAllocations?: ChannelAllocation[]; // purchase lien number: allocation
    public customFields?: string[];
    public customSelections?: string[];

    constructor() {
        this.purchaseId = '';
        this.requestAmount = 0.0;
        this.status = PurchaseStatus.ISSUED;
        this.requesterLogin = '';
        this.creatorLogin = '';
        this.creatorGroupPurchaseType = '';
        this.purchaseType = 'PURCHASE_REQUEST';
        this.supplierDisplayName = '';
    }

    public get getIdentifier(): string {
        return this.purchaseId;
    }

    public get needsUpdate(): boolean {
        return this.status === PurchaseStatus.CHANGE_PURCHASE_PENDING_MERP_APPROVAL;
    }

    private closedStatuses = [PurchaseStatus.CLOSED, PurchaseStatus.SOFT_CLOSED, PurchaseStatus.CANCELLED];

    public get canDisableEdit(): boolean {
        return this.closedStatuses.includes(this.status);
    }
}
