export class Account {
    id?: string;
    name: string;
    code: string;
    costCenter: string;
    accountingString: string;
    active?: boolean;

    constructor() {
        this.code = '';
        this.name = '';
        this.costCenter = '';
        this.accountingString = '';
    }
}
