import { MspModel } from '../MspModel';
import { InvoiceLine } from './InvoiceLine';

export class Invoice extends MspModel {
    public ofaId: string;
    public currency?: string;
    public supplierId?: string;
    public supplierDisplayName?: string;
    public servicePeriodStartDate?: number;
    public servicePeriodEndDate?: number;
    public description?: string;
    public status: string;
    public total: number;
    public createdAtFns?: number;
    public lastUpdatedFns?: number;
    public invoiceNumber?: string;
    public backfill?: string;

    private __lines?: InvoiceLine[];

    constructor() {
        super();

        this.ofaId = '';
        this.currency = '';
        this.supplierId = '';
        this.description = '';
        this.status = '';
        this.total = 0;
        this.deleted = 'N';
    }

    public set invoiceLines(lines: InvoiceLine[]) {
        this.__lines = lines;
    }

    public get invoiceLines(): InvoiceLine[] {
        return this.__lines ?? [];
    }

    public static from(obj: unknown): Invoice {
        return Object.assign(new Invoice(), obj);
    }

    public get isBackfill(): boolean {
        return this.backfill === 'Y';
    }
}
