import { shallowEqualObjects } from "shallow-equal";
import SelectAllState from "../enums/SelectAllState";
import { FilterOptions, SelectedFilterOptions } from "../interfaces/DataGridSortAndFilterProps";
import { Option } from "../interfaces/Option";

/**
 * A function that returns select all state based on isChecked, isPartial flags
 * @param isChecked (boolean) : A flag to indicate if SelectAll option is checked
 * @param isPartial (boolean) : A flag to indicate if SelectAll option is in partial/indeterminate state
 * @returns SelectAllState
 */
export function getSelectAllState(isChecked?: boolean, isPartial?: boolean): SelectAllState {
  if (isChecked) {
    return SelectAllState.ALL;
  }
  if (isPartial) {
    return SelectAllState.PARTIAL;
  }
  return SelectAllState.NONE;
}

/**
 * A function that returns only the checked options contained within the current total options
 * @param checkedOptions (Option[]): List of displayed options that are marked as checked
 * @param totalOptions (Option[]): List of displayed options
 * @returns Option[]
 */
function removedCheckedOptionsNotCurrentlyPresent(
  checkedOptions: Option[],
  totalOptions: Option[]
): Option[] {
  return checkedOptions.filter((option) =>
    totalOptions.find((element) => element.label === option.label && element.value === option.value)
  );
}

/**
 * A function that returns select all state based on checkedOptions, totalOptions
 * @param checkedOptions (Option[]): List of displayed options that are marked as checked
 * @param totalOptions (Option[]): List of displayed options
 * @returns SelectAllState
 */
export function calculateSelectAllStateFromCheckedOptions(
  checkedOptions: Option[],
  totalOptions: Option[]
): SelectAllState {
  const currentCheckedOptions = removedCheckedOptionsNotCurrentlyPresent(
    checkedOptions,
    totalOptions
  );
  if (currentCheckedOptions.length === 0) {
    return SelectAllState.NONE;
  }
  if (currentCheckedOptions.length === totalOptions.length) {
    return SelectAllState.ALL;
  }
  return SelectAllState.PARTIAL;
}

/**
 * A function that returns select all state based on selectAllState, selectAllDisplayedResultsState
 * for determining select all's state when there is filter text
 * @param selectAllState (SelectAllState: state of the select all option
 * @param selectAllDisplayedResultsState (SelectAllState): state of the select all displayed results option
 * @returns SelectAllState
 */
export function calculateSelectAllStateFromDisplayedResultsState(
  selectAllState: SelectAllState,
  selectAllDisplayedResultsState?: SelectAllState
): SelectAllState {
  return selectAllState === selectAllDisplayedResultsState ||
    selectAllDisplayedResultsState === SelectAllState.INITIAL
    ? selectAllState
    : SelectAllState.PARTIAL;
}

/**
 * A function that returns a list of selected options
 * @param include (boolean): A flag to indicate if the selection should be included/excluded from options
 * @param options (Option[]): List of options
 * @param selection (Option[]): List of user interacted options
 * @returns List of selected options
 */
export function getSelectedOptions(
  include: boolean,
  options: Option[],
  selection: Option[]
): Option[] {
  if (include) {
    return options.concat(selection);
  }
  // Difference of options and selection i.e options - selection
  return options.filter(
    (option) =>
      selection.findIndex((selectedOption) => selectedOption.value === option.value) === -1
  );
}

/**
 * A function that returns list of checked options from selectedFilterOptions and filterOptions
 * @param selectedFilterOptions (SelectedFilterOptions) : Contains list of options user interacted with.
 * The options could be selected/deselected based on `in` flag ; true => selection and false => deselection
 * @param filterOptions (FilterOptions) : Contains options displayed for filtering
 * @returns List of checked options
 */
export function getCheckedOptions(
  selectedFilterOptions: SelectedFilterOptions,
  filterOptions: FilterOptions
): Option[] {
  return selectedFilterOptions.in
    ? selectedFilterOptions.options
    : getSelectedOptions(false, filterOptions.options, selectedFilterOptions.options);
}

/**
 * A function that calculates the Select all state from the selectedOptions
 *
 * @param selectedOptions (SelectedFilterOptions) : Contains list of options user interacted with.
 * The options could be selected/deselected based on `in` flag ; true => selection and false => deselection
 * @param filterOptions (FilterOptions) : Contains options displayed for filtering
 * @param hasFilterText (boolean) : A flag to indicate if the search bar has filter text or not
 * @returns SelectAllState i.e ALL, PARTIAL, NONE
 */
export function calculateSelectAllStateFromSelection(
  selectedOptions: SelectedFilterOptions,
  filterOptions: FilterOptions,
  hasFilterText: boolean
): SelectAllState {
  const selectionLength = selectedOptions.options.length;
  if (filterOptions.isPartial) {
    return selectionLength > 0 ? SelectAllState.PARTIAL : selectedOptions.selectAllState;
  }
  if (hasFilterText && selectionLength > 0) {
    return SelectAllState.PARTIAL;
  }
  if (selectionLength === 0) {
    return selectedOptions.in ? SelectAllState.NONE : SelectAllState.ALL;
  }
  if (selectionLength === filterOptions.options.length) {
    return selectedOptions.in ? SelectAllState.ALL : SelectAllState.NONE;
  }
  return SelectAllState.PARTIAL;
}

/**
 * Returns the merged filter options with selected options on top.
 *
 * @param filterOptions The input filter options.
 * @param selectedOptions The selected options to be merged on top.
 * @returns The merged filter options with selected options on top.
 */
export function mergeFilterOptions(
  filterOptions: Option[],
  selectedOptions: Option[] = []
): Option[] {
  const rest = filterOptions.filter(
    (option) => !selectedOptions.some((selection) => shallowEqualObjects(selection, option))
  );
  return [...selectedOptions, ...rest];
}
