import { ConfirmStatus } from '.';
import { PurchaseStatus } from '../enums';
import { PurchaseLine } from './PurchaseLine';

export class Purchase {
    public addressLocationCode?: string;
    public purchaseId: string;
    public orderNumber: string;
    public approvalId?: string;
    public costCenter?: string;
    public creationTime?: number;
    public orderCreationTime?: number;
    public creatorFirstName?: string;
    public CreatorGroup_PurchaseType?: string;
    public creatorLastName?: string;
    public creatorLogin?: string;
    public creatorEmail?: string;
    public currencyCode?: string;
    public lastModifiedTime?: number;
    public location?: string;
    public purchaseType?: string;
    public requestAmount?: number;
    public requestCreationTime?: number;
    public requesterFirstName?: string;
    public requesterLastName?: string;
    public requesterLogin?: string;
    public requesterEmail?: string;
    public mspOwner?: string[];
    public status: string;
    public supplierDisplayName?: string;
    public supplierId?: string;
    public fullyBilledNotifiedInvoiceId?: string;
    public fullyBilledNotified?: boolean;
    public orderId?: string;
    public bigObjective?: string;
    public isPrMspApproved?: boolean;
    public confirmStatus: number;
    public orgName?: string;
    public companyCode?: string;
    public countryCode?: string;
    public requisitionId?: string;
    private sharedRequisition?: number;
    private quantityBased?: number;

    private __lines?: PurchaseLine[];

    constructor() {
        this.purchaseId = '';
        this.orderNumber = '';
        this.purchaseType = 'PURCHASE_REQUEST';
    }

    public set isQuantityBased(value: boolean) {
        this.quantityBased = value ? 1 : 0;
    }

    public get isQuantityBased(): boolean {
        return this.quantityBased === 1;
    }

    public set isSharedRequisition(value: boolean) {
        this.sharedRequisition = value ? 1 : 0;
    }

    public get isSharedRequisition(): boolean {
        return this.sharedRequisition === 1;
    }

    public set lines(lines: PurchaseLine[]) {
        this.__lines = lines;
    }
    public get lines(): PurchaseLine[] {
        return this.__lines || [];
    }

    public deleteLinesForDynamo(): void {
        delete this.__lines;
    }

    private get isClosed() {
        return [PurchaseStatus.CLOSED, PurchaseStatus.SOFT_CLOSED, PurchaseStatus.CANCELLED].includes(
            this.status as PurchaseStatus
        );
    }

    public get confirmationReceiptStatus(): ConfirmStatus {
        if (this.isClosed) {
            return ConfirmStatus.PURCHASE_CLOSED;
        }

        if (this.lines.find((line) => line.unconfirmedReceipts.length > 0)) {
            return ConfirmStatus.NOT_CONFIRMED;
        }

        const atleastOneConfirmedLine = this.lines.reduce((prev, line) => prev + (line.isConfirmed ? 1 : 0), 0);

        return atleastOneConfirmedLine !== 0 ? ConfirmStatus.CONFIRMED : ConfirmStatus.NOT_APPLICABLE;
    }
}
