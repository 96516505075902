import { Country, OrgType } from '../enums';
import { ChannelDistribution } from '.';
import { MspModel } from '../MspModel';

export class CodexActualMapping extends MspModel {
    public costCenter: string;
    public glAccount: string;
    public country: Country;
    public orgType: OrgType;
    public bigObjective: string;
    public smartE: string;
    public channelDistribution: ChannelDistribution[];
    public costCenterGlAccount: string;
    public periodStart: number;
    public periodEnd: number;

    constructor() {
        super();

        this.costCenter = '';
        this.glAccount = '';
        this.country = Country.US;
        this.orgType = OrgType.AUTOMATED;
        this.bigObjective = '';
        this.smartE = '';
        this.channelDistribution = [];
        this.costCenterGlAccount = '';
        this.periodStart = 0;
        this.periodEnd = 0;
    }
}
