import React from "react";
import { Column, SelectColumn } from "react-data-grid";
import { SelectionType } from "../../interfaces/Selection";

const DEFAULT_WIDTH = 55;

interface SelectionColumnProps {
  selectionType?: SelectionType;
  cellClass?: string;
  headerCellClass?: string;
}

export const MultiSelectColumn = <T extends unknown>({
  cellClass,
  headerCellClass,
}: SelectionColumnProps): Column<T, unknown> => {
  return {
    ...SelectColumn,
    width: DEFAULT_WIDTH,
    maxWidth: DEFAULT_WIDTH,
    headerCellClass,
    cellClass,
  };
};

export const SingleSelectColumn = <T extends unknown>({
  cellClass,
  headerCellClass,
}: SelectionColumnProps): Column<T, unknown> => {
  return {
    ...MultiSelectColumn({ cellClass, headerCellClass }),
    headerRenderer: () => <span data-testid="single-select-header" />,
  };
};

export const SelectionColumn = <T extends unknown>({
  selectionType,
  cellClass,
  headerCellClass,
}: SelectionColumnProps): Column<T, unknown> | null => {
  if (selectionType === "single") {
    return SingleSelectColumn({ cellClass, headerCellClass });
  }
  if (selectionType === "multi") {
    return MultiSelectColumn({ cellClass, headerCellClass });
  }
  return null;
};

export default SelectionColumn;
