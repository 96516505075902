import { v4 } from 'uuid';

export abstract class MspModel {
    public id: string;
    public createdAt: number;
    public updatedAt: number;

    protected deleted: string;

    constructor() {
        this.id = v4();
        const timeStamp = Date.now();
        this.createdAt = timeStamp;
        this.updatedAt = timeStamp;
        this.deleted = 'N';
    }

    public get isDeleted(): boolean {
        return this.deleted === 'Y';
    }

    public set isDeleted(value: boolean) {
        this.deleted = value ? 'Y' : 'N';
    }
}
