const customCSSPropertiesMap: Record<string, string> = {
  activeDrawerWidth: '--awsui-active-drawer-width-3j0evk',
  offsetTop: '--awsui-offset-top-3j0evk',
  offsetTopWithNotifications: '--awsui-offset-top-with-notifications-3j0evk',
  contentGapLeft: '--awsui-content-gap-left-3j0evk',
  contentGapRight: '--awsui-content-gap-right-3j0evk',
  contentHeight: '--awsui-content-height-3j0evk',
  defaultMaxContentWidth: '--awsui-default-max-content-width-3j0evk',
  defaultMinContentWidth: '--awsui-default-min-content-width-3j0evk',
  footerHeight: '--awsui-footer-height-3j0evk',
  headerHeight: '--awsui-header-height-3j0evk',
  layoutWidth: '--awsui-layout-width-3j0evk',
  mainOffsetLeft: '--awsui-main-offset-left-3j0evk',
  maxContentWidth: '--awsui-max-content-width-3j0evk',
  minContentWidth: '--awsui-min-content-width-3j0evk',
  notificationsHeight: '--awsui-notifications-height-3j0evk',
  overlapHeight: '--awsui-overlap-height-3j0evk',
  navigationWidth: '--awsui-navigation-width-3j0evk',
  splitPanelReportedHeaderSize: '--awsui-split-panel-reported-header-size-3j0evk',
  splitPanelReportedSize: '--awsui-split-panel-reported-size-3j0evk',
  splitPanelHeight: '--awsui-split-panel-height-3j0evk',
  splitPanelMinWidth: '--awsui-split-panel-min-width-3j0evk',
  splitPanelMaxWidth: '--awsui-split-panel-max-width-3j0evk',
  toolsMaxWidth: '--awsui-tools-max-width-3j0evk',
  toolsWidth: '--awsui-tools-width-3j0evk',
  toolsAnimationStartingOpacity: '--awsui-tools-animation-starting-opacity-3j0evk',
  contentScrollMargin: '--awsui-content-scroll-margin-3j0evk',
  flashbarStackDepth: '--awsui-flashbar-stack-depth-3j0evk',
  flashbarStackIndex: '--awsui-flashbar-stack-index-3j0evk',
  flashbarStickyBottomMargin: '--awsui-flashbar-sticky-bottom-margin-3j0evk',
  stackedNotificationsBottomMargin: '--awsui-stacked-notifications-bottom-margin-3j0evk',
  stackedNotificationsDefaultBottomMargin: '--awsui-stacked-notifications-default-bottom-margin-3j0evk',
};
export default customCSSPropertiesMap;
