import { RollUp } from './RollUp';

export class YTDRollUp extends RollUp {
    public ytd = 0;
    public jan = 0;
    public feb = 0;
    public mar = 0;
    public apr = 0;
    public may = 0;
    public jun = 0;
    public jul = 0;
    public aug = 0;
    public sep = 0;
    public oct = 0;
    public nov = 0;
    public dec = 0;
}
