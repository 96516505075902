import {
  NonCancelableCustomEvent,
  Select,
  SelectProps,
} from "@amzn/awsui-components-react-v3/polaris";
import { OptionDefinition } from "@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces";
import { OptionsLoadItemsDetail } from "@amzn/awsui-components-react-v3/polaris/internal/components/options-filtering/interfaces";
import React from "react";
import {
  SelectDropDownEditorProps,
  SelectOptionDefinition,
} from "../interfaces/SelectDropDownEditorProps";

const SelectDropDownEditor: React.FC<SelectDropDownEditorProps> = ({
  dropDownOptions,
  statusType,
  loadingText,
  noMatchText,
  errorText,
  recoveryText,
  noOptionsText,
  placeholderText,
  onChange,
  currentValue,
  onRetry,
  unSelectValue,
}): JSX.Element => {
  const [selectedOption, setSelectedOption] = React.useState<OptionDefinition | null>(
    currentValue.option || null
  );

  const onOptionChange = (detail: NonCancelableCustomEvent<SelectProps.ChangeDetail>): void => {
    let newOption: OptionDefinition;
    if (detail.detail.selectedOption.value === unSelectValue) {
      newOption = { value: "" };
    } else {
      newOption = detail.detail.selectedOption;
    }
    setSelectedOption(newOption);
    onChange(new SelectOptionDefinition(newOption));
  };

  const onLoadItems = (detail: NonCancelableCustomEvent<OptionsLoadItemsDetail>): void => {
    if (!onRetry) {
      return;
    }
    const isRefetchRequired = detail.detail.samePage;
    if (isRefetchRequired) {
      onRetry();
    }
  };

  return (
    <>
      <Select
        options={dropDownOptions}
        selectedOption={selectedOption}
        onChange={onOptionChange}
        statusType={statusType}
        filteringType="auto"
        loadingText={loadingText || "Loading"}
        noMatch={noMatchText || "We can’t find a match"}
        empty={noOptionsText || "No options"}
        errorText={errorText || "Error fetching results."}
        recoveryText={recoveryText || "Retry"}
        placeholder={placeholderText || "Choose an option"}
        onLoadItems={onLoadItems}
        selectedAriaLabel="selected"
      />
    </>
  );
};

export default SelectDropDownEditor;
