import { RollUp } from './RollUp';

export class SMARTeRollUp extends RollUp {
    public countryChannelSmartE: string;

    public country: string;
    public channel: string;
    public smartE: string;
    public currentYear = 0;
    public previousYear = 0;
    public nextYear = 0;
}
