import { MspModel } from '../MspModel';

export class ChannelAllocation extends MspModel {
    constructor() {
        super();
    }

    public version: number;
    public channelAmount: number;
    public channelType: string;
    public lineNumber: number;
    public purchaseId: string;
    public receiptNumber: string;
    public smartE?: string;
}
