import { Country, OrgType, ChannelType } from '../enums';
import { MspModel } from '../MspModel';

export class CodexActual extends MspModel {
    public period: number;
    public costCenter: string;
    public glAccount: string;
    public country: Country;
    public orgType?: OrgType;
    public amount: number;
    public channel?: ChannelType;
    public bigObjective?: string;
    public smartE?: string;
    public costCenterGlAccountCountry: string;
    public countryChannel?: string;

    constructor() {
        super();

        this.period = 0;
        this.costCenter = '';
        this.glAccount = '';
        this.country = Country.US;
        this.amount = 0;
        this.costCenterGlAccountCountry = '';
    }
}
