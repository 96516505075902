export class Message {
    threadId: string;
    messageId: string;
    creatorLogin: string;
    creatorFirstName?: string;
    creatorLastName?: string;
    creatorTitle?: string;
    creationTime: number;
    content: string;

    constructor() {
        this.threadId = '';
        this.messageId = '';
        this.creatorLogin = '';
        this.creatorFirstName = '';
        this.creatorLastName = '';
        this.creatorTitle = '';
        this.creationTime = Date.now();
        this.content = '';
    }
}
