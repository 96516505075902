export enum ChannelType {
    PAID_SEARCH = 'Paid Search',
    PAID_SEARCH_GOOGLE_YOUTUBE = 'Paid Search (Google / YouTube)',
    SOCIAL_AUTOMATED = 'Social - Automated',
    SOCIAL_AUTOMATED_FACEBOOK_INSTAGRAM = 'Social -Automated (Facebook / Instagram)',
    ASSOCIATES = 'Associates',
    OTHER_AUTOMATED = 'Other Automated',
    TV = 'TV',
    POLV = 'POLV (Premium Online Video)',
    RADIO = 'Radio',
    PRINT = 'Print',
    DIGITAL = 'Digital (Display & OLV)',
    CINEMA = 'Cinema',
    OOH = 'OOH',
    SPONSORSHIPS = 'Sponsorships',
    SOCIAL_FIXED = 'Social - Fixed',
    ACCESS = 'Access',
    MEDIA_FEE = 'Media Fee',
    OTHER_MEDIA = 'Other Media',
    FIXED_MEDIA_PRODUCTION_EXPENSE = 'Fixed Media Production Expense',
    OTHER_NON_MEDIA = 'Other Non-Media',
    NOT_APPLICABLE = 'Not Applicable',
}
