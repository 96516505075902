import { BigNumber } from 'bignumber.js';
import { ReceiptType, Receipt } from '../receipt';
import { CoupaPurchaseLineType, PurchaseLineVersionCategory } from '../enums';
import { Rounder } from '../../core/class';

export class PurchaseLine {
    public billingAccountCode?: string;
    public billingAccountName?: string;
    public channel?: string;
    public commodity?: string;
    public company?: string;
    public costCenter?: string;
    public currencyCode?: string;
    public glAccount?: string;
    public lastModifiedTime?: number;
    public lineAmount?: number;
    public lineDescription?: string;
    public lineId: number;
    public lineNumber: number;
    public location?: string;
    public needByDate?: number;
    public productLine?: string;
    public lineType?: CoupaPurchaseLineType;
    public project?: string;
    public purchaseId: string;
    public allocatedAmount?: number;
    public uncommittedBalance: number;
    public version?: number;
    public purchaseLineStatus?: string;
    public customEighthSegment?: string;
    public esLastUpdated?: number;
    public creationTime?: number;
    public coupaVersion?: number;
    public paymentTermCode?: string;
    public supplierId?: string;
    public pricePerUnit?: number;
    public quantity?: number;
    public uom?: string;
    public versionCategory?: PurchaseLineVersionCategory;

    private __receipts?: Receipt[];

    private static readonly DECIMAL_PLACES = 2;

    constructor() {
        this.lineId = 0;
        this.purchaseId = '';
        this.allocatedAmount = 0;
        this.uncommittedBalance = 0;
        this.quantity = 1.0;
    }

    public deleteReceipts(): void {
        delete this.__receipts;
    }

    public get usedAmount() {
        const used = this.getUsed();

        return Rounder.roundToFixed(used);
    }

    public get unusedAmount() {
        const used = this.getUsed();
        const unused = new BigNumber(this.lineAmount || 0).minus(used);

        return Rounder.roundToFixed(unused);
    }

    private getUsed() {
        return this.receipts
            .filter((r) => r.consumesLineAmount)
            .reduce((total, { receiptAmount }) => total.plus(receiptAmount), new BigNumber(0));
    }

    public get receipts(): Receipt[] {
        return this.__receipts || [];
    }

    public set receipts(receipts: Receipt[]) {
        this.__receipts = receipts;
    }

    public get billedAmount(): number {
        const totalBilledAmount = this.receipts.reduce((p, { billedAmount }) => p.plus(billedAmount), new BigNumber(0));

        return Rounder.roundToFixed(totalBilledAmount, PurchaseLine.DECIMAL_PLACES);
    }

    public get unBilledAmount(): number {
        const totalUnBilledAmount = this.receipts.reduce((p, { unBilled }) => p.plus(unBilled), new BigNumber(0));

        return Rounder.roundToFixed(totalUnBilledAmount, PurchaseLine.DECIMAL_PLACES);
    }

    public get unconfirmedReceipts(): Receipt[] {
        return this.receipts.filter((r) => r.requiresConfirmation);
    }

    public get receiptsDueOnTheCurrentMonth(): Receipt[] {
        return this.receipts.filter((r) => r.getReceiptType() === ReceiptType.CURRENT_MONTH_RECEIPT);
    }

    public get isQuantityBased() {
        return this.lineType
            ? [CoupaPurchaseLineType.ORDER_QUANTITY_LINE, CoupaPurchaseLineType.REQUEST_QUANTITY_LINE].includes(
                  this.lineType
              )
            : false;
    }
    public get isConfirmed(): boolean {
        if (!this.receipts?.length) {
            return false;
        }

        return this.receipts.every((receipt) => !receipt.requiresConfirmation);
    }
}
