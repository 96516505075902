import { MspModel } from '../MspModel';
import { BudgetStatus, BudgetStep, GuidanceCycle, BudgetApproval, BudgetSLA } from '.';

export class Budget extends MspModel {
    public createdBy: string;
    public requestedBy: string;
    public approvedBy: string;
    public currentApproval: BudgetApproval;
    public approvals: BudgetApproval[];
    public team: string;
    public waitToken: string;
    public guidanceCycle: GuidanceCycle;
    public version: string;
    public step: BudgetStep;
    public status: BudgetStatus;
    protected locked: string;
    public sla: BudgetSLA;
    public lastCognosSync: number;
    public ignoredAccountingStrings: string[];
    public teamYear: string;
    public year: number;

    public get isLocked(): boolean {
        return this.locked === 'Y';
    }

    public set isLocked(value: boolean) {
        this.locked = value ? 'Y' : 'N';
    }
}
