import { VersionType } from '../../models';
import { ReceiptVersion } from '../receipt/ReceiptVersion';

export class PurchaseLineVersion {
    public purchaseId: string;
    public lineVersionLineNumber: string;
    public versionType: VersionType;
    public purchaseIdIsConfirmProcess: string;
    public purchaseIdIsLineAmountChanged: string;
    public lineVersion: number;
    public lineNumber: number;
    public orderId?: string;
    public lineId?: number;
    public lineDescription?: string;
    public receiptStatus?: string;
    public isConfirmProcess?: number;
    public isLineAmountChanged?: number;
    public changedLineAmount?: number;
    public allocatedAmount?: number;
    public currencyCode?: string;
    public originalUncommittedBalance?: number;
    public modifiedUncommittedBalance?: number;
    public orderStatus?: string;
    public purchaseLineStatus?: string;
    public isFirst?: number;
    public updaterLogin?: string;
    public purchaseIdVersionType?: string;
    public invoiceNumber?: string;

    public PurchaseIdLineNumberOrderStatus?: string;

    public __receiptVersions?: ReceiptVersion[];

    constructor() {
        this.purchaseId = '';
        this.lineVersionLineNumber = '';
        this.versionType = VersionType.SPEND_EDIT;
        this.purchaseIdIsConfirmProcess = '';
        this.purchaseIdIsLineAmountChanged = '';
        this.lineVersion = 0;
        this.lineNumber = 0;
    }

    public deleteReceiptVersionsForDynamo(): void {
        delete this.__receiptVersions;
    }

    public get receiptVersions(): ReceiptVersion[] {
        return this.__receiptVersions || [];
    }

    public set receiptVersions(receipts: ReceiptVersion[]) {
        this.__receiptVersions = receipts;
    }
}
