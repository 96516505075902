import { BackfillStatus, CoupaPurchaseLineType, PurchaseLineStatus, PurchaseStatus } from '../';

export class StagedPurchase {
    public purchaseId: string;
    public requisitionId: string;
    public orderId: string;
    public orderNumber: string;
    public lineId: number;
    public lineNumber: number;
    public lineQuantity: number;
    public pricePerUnit?: number;
    public supplierDisplayName?: string;
    public supplierId?: string;
    public lineDescription?: string;
    public company?: string;
    public location?: string;
    public costCenter?: string;
    public glAccount?: string;
    public productLine?: string;
    public channel?: string;
    public project?: string;
    public customEighthSegment?: string;
    public lineAmount: number;
    public billingAccountName?: string;
    public requesterLogin?: string;
    public creatorLogin?: string;
    public creatorGroup_PurchaseType?: string;
    public purchaseLineStatus?: PurchaseLineStatus;
    public purchaseStatus?: PurchaseStatus;
    public orderCreationTime?: number;
    public updatedAt?: number;
    public currencyCode?: string;
    public onboardingRequestId: string;
    public status: BackfillStatus;
    public lineType: CoupaPurchaseLineType;
}
