import { COASegment } from './COASegment';

export class TrieNode {
    result?: string;
    coaSegments: Map<COASegment, string>;
    superUserTeamId: string;
    children: Map<string, TrieNode>;

    constructor() {
        this.children = new Map();
    }

    public isEnd(): boolean {
        return this.result !== undefined;
    }

    public hasNode(segment: string): boolean {
        return Array.from(this.children.keys()).includes(segment);
    }

    public getNode(segment: string): TrieNode | undefined {
        return this.children.get(segment);
    }

    public insertNode(segment: string): TrieNode {
        const childNode = new TrieNode();
        this.children.set(segment, childNode);
        return childNode;
    }
}
