import { MspModel } from '../MspModel';

export class CountryMapping extends MspModel {
    companyCode: string;
    countryCode: string;
    version: string;

    constructor() {
        super();
    }
}
