export class BudgetApproval {
    public approver: string;
    public approvalDate: number;
    public approvalStep: string;
    public approval: BudgetApprovalStatus;
}

export enum BudgetApprovalStatus {
    APPROVED = 'APPROVED',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    CHANGES_REQUESTED = 'CHANGES_REQUESTED',
}
