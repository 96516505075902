import { MspModel } from '../MspModel';

export class FxRate extends MspModel {
    public currency: string;
    public conversionRate: number;

    public static from(currency: string, conversionRate: number): FxRate {
        const fxRate = new FxRate();

        fxRate.currency = currency;
        fxRate.conversionRate = conversionRate;

        return fxRate;
    }
}
