import React, { ReactNode } from "react";
import styles from "./ToolsHeader.module.scss";

interface ToolsHeaderProps {
  header?: ReactNode;
  pagination?: ReactNode;
  preferences?: ReactNode;
}

export const ToolsHeader = ({ header, pagination, preferences }: ToolsHeaderProps): JSX.Element => (
  <div>
    {header}
    <div className={styles.tools}>
      <div className={styles["tools-align-right"]}>
        {pagination && <div className={styles["tools-pagination"]}>{pagination}</div>}
        {preferences && <div className={styles["tools-preferences"]}>{preferences}</div>}
      </div>
    </div>
  </div>
);

ToolsHeader.defaultProps = {
  header: "",
  pagination: "",
  preferences: "",
};

export default ToolsHeader;
