export enum BigObjectiveType {
    'alexa' = 'Alexa (Just Ask)',
    'prime' = 'Prime (Engage Prime)',
    'pvBlockbuster' = 'PV Blockbusters',
    'embraceAmazon' = 'Embrace Amazon/Fundamentals',
    'amazonBrandReputation' = 'Amazon Brand Reputation',
    'groceries' = 'Groceries',
    'theClimatePledge' = 'The Climate Pledge',
    'others' = 'Others (Program/Category-Driven)',
    'notApplicable' = 'Not Applicable',
}
