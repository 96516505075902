export class Supplier {
    id?: number;
    name?: string;
    displayName: string;
    number?: string;
    status?: string;
    internal?: boolean;

    constructor() {
        this.displayName = '';
    }
}
