import { MspModel } from '../MspModel';

export class InvoiceAllocation extends MspModel {
    public invoiceId: string;
    public invoiceOfaId: string;
    public invoiceLineId: string;
    public purchaseLineId: number;
    public receiptNumber: string;
    public allocatedAmount: number;
    public invoiceTotal: number;
    public invoiceNumber?: string;

    constructor() {
        super();

        this.invoiceId = '';
        this.invoiceOfaId = '';
        this.invoiceLineId = '';
        this.purchaseLineId = 0;
        this.receiptNumber = '';
        this.allocatedAmount = 0;
        this.invoiceTotal = 0;
    }
}
