import { ChannelType } from '../enums';

export class ChannelDistribution {
    public channelType: ChannelType;
    public percentage: number;

    constructor() {
        this.channelType = ChannelType.ACCESS;
        this.percentage = 0;
    }
}
