export class YearlySpend {
    public jan: number;
    public feb: number;
    public mar: number;
    public apr: number;
    public may: number;
    public jun: number;
    public jul: number;
    public aug: number;
    public sep: number;
    public oct: number;
    public nov: number;
    public dec: number;
    public q1: number;
    public q2: number;
    public q3: number;
    public q4: number;
    public total: number;

    constructor() {
        this.jan = 0;
        this.feb = 0;
        this.mar = 0;
        this.apr = 0;
        this.may = 0;
        this.jun = 0;
        this.jul = 0;
        this.aug = 0;
        this.sep = 0;
        this.oct = 0;
        this.nov = 0;
        this.dec = 0;
        this.q1 = 0;
        this.q2 = 0;
        this.q3 = 0;
        this.q4 = 0;
        this.total = 0;
    }

    // TODO: https://sim.amazon.com/issues/MSP-636

    // public get Jan(): number {
    //     return this.jan;
    // }

    // public set Jan(value: number) {
    //     this.jan = value;
    //     this.adjustTotals();
    // }

    // public get Feb(): number {
    //     return this.feb;
    // }

    // public set Feb(value: number) {
    //     this.feb = value;
    //     this.adjustTotals();
    // }

    // public get Mar(): number {
    //     return this.mar;
    // }

    // public set Mar(value: number) {
    //     this.mar = value;
    //     this.adjustTotals();
    // }

    // public get Apr(): number {
    //     return this.apr;
    // }

    // public set Apr(value: number) {
    //     this.apr = value;
    //     this.adjustTotals();
    // }

    // public get May(): number {
    //     return this.may;
    // }
    // public set May(value: number) {
    //     this.may = value;
    //     this.adjustTotals();
    // }

    // public get Jun(): number {
    //     return this.jun;
    // }
    // public set Jun(value: number) {
    //     this.jun = value;
    //     this.adjustTotals();
    // }

    // public get Jul(): number {
    //     return this.jul;
    // }
    // public set Jul(value: number) {
    //     this.jul = value;
    //     this.adjustTotals();
    // }

    // public get Aug(): number {
    //     return this.aug;
    // }
    // public set Aug(value: number) {
    //     this.aug = value;
    //     this.adjustTotals();
    // }

    // public get Sep(): number {
    //     return this.sep;
    // }
    // public set Sep(value: number) {
    //     this.sep = value;
    //     this.adjustTotals();
    // }

    // public get Oct(): number {
    //     return this.oct;
    // }
    // public set Oct(value: number) {
    //     this.oct = value;
    //     this.adjustTotals();
    // }

    // public get Nov(): number {
    //     return this.nov;
    // }

    // public set Nov(value: number) {
    //     this.nov = value;
    //     this.adjustTotals();
    // }

    // public get Dec(): number {
    //     return this.dec;
    // }

    // public set Dec(value: number) {
    //     this.dec = value;
    //     this.adjustTotals();
    // }

    // public get Q1(): number {
    //     return this.q1;
    // }
    // public get Q2(): number {
    //     return this.q2;
    // }
    // public get Q3(): number {
    //     return this.q3;
    // }
    // public get Q4(): number {
    //     return this.q4;
    // }
    // public get Total(): number {
    //     return this.total;
    // }

    // private adjustTotals(): void {
    //     this.q1 = this.jan + this.feb + this.mar;
    //     this.q2 = this.apr + this.may + this.jun;
    //     this.q3 = this.jul + this.aug + this.sep;
    //     this.q4 = this.oct + this.nov + this.dec;

    //     this.total = this.q1 + this.q2 + this.q3 + this.q4;
    // }
}
