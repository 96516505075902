import { CSSProperties } from "react";
import { DataGridHandle } from "react-data-grid";
import { SelectionType } from "../interfaces/Selection";
import { PopoverPlacement } from "../interfaces/CellPopoverProps";

const SCROLL_BAR = 20;
const EMPTY_HEIGHT = 500;
export const DEFAULT_ROW_HEIGHT = 40;
export const DEFAULT_HEADER_ROW_HEIGHT = 40;

export interface SelectedCell {
  rowIdx: number;
  colIdx: number;
}

export const getDynamicTableHeight = (rowsCount?: number, isLoading?: boolean): number => {
  if (!rowsCount || isLoading) {
    return EMPTY_HEIGHT;
  }
  const safeRowHeight = DEFAULT_ROW_HEIGHT;
  const safeHeaderRowHeight = DEFAULT_HEADER_ROW_HEIGHT;
  return rowsCount * safeRowHeight + safeHeaderRowHeight + SCROLL_BAR;
};

export const getTableHeightClassName = (rowsCount: number): string => {
  return `table-height-${Math.ceil(rowsCount / 10)}`;
};

export const getOrDefault = <T>(value: T | null | undefined, defaultValue: T): T => {
  return value ?? defaultValue;
};

export const getPortalPositioningInGrid = (top: number, left: number): CSSProperties => {
  return {
    position: "absolute",
    top,
    left,
    zIndex: 1,
  };
};

export const getCSSPropertiesForCellPortal = (
  portalTarget: HTMLElement,
  cellElement: HTMLElement | null | undefined,
  placement: PopoverPlacement
): CSSProperties => {
  let cellTop = 0;
  let cellLeft = 0;
  let cellWidth = 0;
  if (cellElement) {
    const cellRect = cellElement?.getBoundingClientRect();
    cellTop = getOrDefault(cellRect?.top, 0);
    cellLeft = getOrDefault(cellRect?.left, 0);
    cellWidth = getOrDefault(cellRect?.width, 0);
  }
  const portalTargetRect = portalTarget.getBoundingClientRect();
  const portalLeft = getOrDefault(portalTargetRect.left, 0);
  const portalTop = getOrDefault(portalTargetRect.top, 0);
  const top = placement === PopoverPlacement.below ? cellTop + DEFAULT_ROW_HEIGHT : cellTop;
  const left = placement === PopoverPlacement.right ? cellLeft + cellWidth : cellLeft;
  return getPortalPositioningInGrid(top - portalTop, left - portalLeft);
};

/**
 * Returns the cell element for given row and column.
 *
 * @param grid The data grid handle.
 * @param rowIdx The 1-based row index including header row.
 * @param colIdx The 1-based column index including selection column (if any).
 * @returns The corresponding cell element otherwise undefined.
 */
export const getCellElement = (
  grid: DataGridHandle | null,
  rowIdx: number,
  colIdx: number
): HTMLElement | null | undefined => {
  return grid?.element?.querySelector(
    `:scope > div[role="row"][aria-rowIndex="${rowIdx}"] > div[role='gridcell'][aria-colIndex="${colIdx}"]`
  );
};

/**
 * Returns the 0-based indexes for the selected cell (if any).
 *
 * @param grid The data grid handle.
 * @param selectionType Selection type for the grid to determine if selection column is present or not.
 * @returns The 0-based indexes for the selected cell (if any) otherwise undefined.
 */
export const getSelectedCell = (
  grid: DataGridHandle | null,
  selectionType?: SelectionType
): SelectedCell | undefined => {
  const cell = grid?.element?.querySelector(
    ":scope > div[role='row'] > div[role='gridcell'][aria-selected='true']"
  );

  const row = cell?.closest("div[role='row']");
  const colOffset = selectionType ? 2 : 1;

  const rowIdx = +getOrDefault(row?.getAttribute("aria-rowindex"), "0") - 2;
  const colIdx = +getOrDefault(cell?.getAttribute("aria-colindex"), "0") - colOffset;

  if (rowIdx >= 0 && colIdx >= 0) {
    return {
      rowIdx,
      colIdx,
    };
  }
  return undefined;
};
