export enum AttributeTypes {
    BOOL = 'bool',
    MUST = 'must',
    FILTER = 'filter',
    SHOULD = 'should',
    MATCH = 'match',
    RANGE = 'range',
    TERMS = 'terms',
    TERM = 'term',
    QUERY = 'query',
    SORT = 'sort',
    MUST_NOT = 'must_not',
    SOURCE = 'source',
    SCRIPT = 'script',
    MAX = 'max',
    MIN = 'min',
    AGGS = 'aggs',
}
