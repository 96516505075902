export enum COASegment {
    COMPANY = 'COMPANY',
    LOCATION = 'LOCATION',
    COST_CENTER = 'COST_CENTER',
    GL_ACCOUNT = 'GL_ACCOUNT',
    PRODUCT_LINE = 'PRODUCT_LINE',
    CHANNEL = 'CHANNEL',
    PROJECT = 'PROJECT',
    CUSTOM_EIGHTH_SEGMENT = 'CUSTOM_EIGHTH_SEGMENT',
}

const COASegmentOrder = [
    COASegment.COMPANY,
    COASegment.LOCATION,
    COASegment.COST_CENTER,
    COASegment.GL_ACCOUNT,
    COASegment.PRODUCT_LINE,
    COASegment.CHANNEL,
    COASegment.PROJECT,
    COASegment.CUSTOM_EIGHTH_SEGMENT,
];

export function nextCOASegment(segment: COASegment): COASegment | undefined {
    const segmentIndex = COASegmentOrder.indexOf(segment);
    if (segmentIndex >= COASegmentOrder.length) return undefined;

    return COASegmentOrder[segmentIndex + 1];
}
