import { v4 as uuid } from 'uuid';
import { EditSpendRow, UpdateStatus } from '.';

export class BulkUploadRecord {
    public recordId: string;
    public reportId: string;
    public orderNumber?: string;
    public updateStatus?: UpdateStatus;
    public userInputTime: number;
    public updateTime?: number;
    public lambdaExecutionId?: string;
    public editSpendRecords?: EditSpendRow[];
    public lineNumber: number;
    constructor() {
        this.recordId = uuid();
        this.reportId = '';
        this.userInputTime = 0;
    }
}
