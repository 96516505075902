export class Owner {
    public login: string;
    public purchaseId: string;
    public firstName?: string;
    public lastName?: string;
    public email?: string;
    public addedBy?: string;
    public grantedTime?: number;
    public removedBy?: string;
    public revokedTime?: number;
    public isAccessRevoked: string;

    constructor() {
        this.login = '';
        this.purchaseId = '';
        this.isAccessRevoked = '';
    }
}
