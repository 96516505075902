export class Configuration {
    public Namespace: string;
    public Key: string;
    public Value: string;

    constructor() {
        this.Namespace = '';
        this.Key = '';
        this.Value = '';
    }
}
