import { v4 } from 'uuid';
import { ChannelTypeDetail } from './AccountingString';
import { YearlySpend } from './YearlySpend';

export class YearlyAccountingForecast extends YearlySpend {
    public id: string;
    public createdAt: number;
    public updatedAt: number;
    public deleted: string;
    public team: string;
    public year: number;
    public teamYear: string;
    public currency: string;
    public accountingString: string;
    public owner: string;
    public coa1: string;
    public coa2: string;
    public coa3: string;
    public coa4: string;
    public coa5: string;
    public coa6: string;
    public coa7: string;
    public coa8: string;
    public taskStatus?: string;
    public comment?: string;
    public conversionRate?: number;
    public conversionRateDate?: number;
    public bigObjective?: string;
    public channelTypes?: ChannelTypeDetail;

    constructor() {
        super();
        this.id = v4();
        this.createdAt = Date.now();
        this.updatedAt = Date.now();
        this.deleted = 'N';
    }

    public get isDeleted(): boolean {
        return this.deleted === 'Y';
    }

    public set isDeleted(value: boolean) {
        this.deleted = value ? 'Y' : 'N';
    }

    public static from(value: Partial<YearlyAccountingForecast>): YearlyAccountingForecast {
        const row = Object.assign(new YearlyAccountingForecast(), value);

        row.teamYear = `${row.team}$${row.year}`;
        return row;
    }
}
