import { OnboardingStatus, PurchaseStatus } from '../';
import { v4 } from 'uuid';

export class OnboardingRequest {
    public id: string;
    public requestPurchaseStatus?: PurchaseStatus;
    public OnboardingRequestStartDate?: number;
    public OnboardingRequestEndDate?: number;
    public requestStatus?: OnboardingStatus;
    public createdAt: number;
    public filterIds: string[];
    public teamName: string;
    public requester: string;
    public requesterEmail: string;
    public requesterFirstName: string;
    public requesterLastName: string;
    public requesterTimeZone: string;
    public onboardingOperator: string;
    public cradleQuery?: string;
    public posCreated?: number;
    public poLineCreated?: number;
    public poLinesFetched?: number;

    constructor() {
        this.id = v4();
        this.createdAt = Date.now();
    }

    public set updateFilterIds(filterIds: string[]) {
        const uniqueFilterIds = new Set(filterIds);
        this.filterIds = [...uniqueFilterIds];
    }
}
