export const REGION = 'us-west-2';

export enum Stages {
    BETA = 'beta',
    PROD = 'prod',
}

export const updateInvoiceDbFailedMetric = 'Metrics: updateInvoice.DynamoDb.Exception.Count';
export const createInvoiceAllocationDbFailedMetric = 'Metrics: createInvoiceAllocation.DynamoDb.Exception.Count';
export const updateInvoiceAllocationDbFailedMetric = 'Metrics: updateInvoiceAllocation.DynamoDb.Exception.Count';
export const createInvoiceLineDbFailedMetric = 'Metrics: createInvoiceLine.DynamoDb.Exception.Count';
export const updatePurchaseLineDbFailedMetric = 'Metrics: updatePurchaseLine.DynamoDb.Exception.Count';
export const updateReceiptDbFailedMetric = 'Metrics: updateReceipt.DynamoDb.Exception.Count';
export const batchUpdateReceiptsDbFailedMetric = 'Metrics: batchUpdateReceipts.DynamoDb.Exception.Count';
export const batchUpdateChannelAllocationDbFailedMetric =
    'Metrics: batchUpdateChannelAllocation.DynamoDb.Exception.Count';
export const setConfigurationDbFailedMetric = 'Metrics: setConfiguration.DynamoDb.Exception.Count';
export const updatePurchaseDbFailedMetric = 'Metrics: updatePurchase.DynamoDb.Exception.Count';
export const updatePurchaseLineVersionDbFailedMetric = 'Metrics: updatePurchaseLineVersion.DynamoDb.Exception.Count';
export const batchUpdateReceiptVersionsDbFailedMetric = 'Metrics: updateReceiptVersion.DynamoDb.Exception.Count';
export const getPurchaseLineVersionDbFailedMetric = 'Metrics: getPurchaseLineVersion.DynamoDb.Exception.Count';
export const getNumberOfPostedReceiptsByTime = 'Metrics: getNumberOfPostedReceiptsByTime.DynamoDb.Exception.Count';
export const createCodexActualDbFailedMetric = 'Metrics: createCodexActual.DynamoDb.Exception.Count';
export const updateCodexActualDbFailedMetric = 'Metrics: updateCodexActual.DynamoDb.Exception.Count';
export const batchUpdateCodexActualsDbFailedMetric = 'Metrics: batchUpdateCodexActualMapping.DynamoDb.Exception.Count';
export const createCodexActualMappingDbFailedMetric = 'Metrics: createCodexActualMapping.DynamoDb.Exception.Count';
export const updateCodexActualMappingDbFailedMetric = 'Metrics: updateCodexActualMapping.DynamoDb.Exception.Count';
export const batchUpdateCodexActualMappingsDbFailedMetric =
    'Metrics: batchUpdateCodexActualMapping.DynamoDb.Exception.Count';
export const createBulkUploadRecordDbFailedMetric = 'Metrics: createBulkUploadRecord.DynamoDb.Exception.Count';
export const errorNameSpace = 'DB/FAIL';

export const defaultEmailDelay = 5;
