export enum Country {
    SEVEN_K = '7K',
    NINE_S = '9S',
    AA = 'AA',
    AE = 'AE',
    AU = 'AU',
    BR = 'BR',
    CA = 'CA',
    CN = 'CN',
    CO = 'CO',
    DE = 'DE',
    EG = 'EG',
    ES = 'ES',
    EU = 'EU',
    FR = 'FR',
    HM = 'HM',
    HN = 'HN',
    IN = 'IN',
    IT = 'IT',
    JP = 'JP',
    K_ONE = 'K1',
    MX = 'MX',
    NL = 'NL',
    P_ONE = 'P1',
    PL = 'PL',
    SA = 'SA',
    SE = 'SE',
    SG = 'SG',
    TB = 'TB',
    TF = 'TF',
    TR = 'TR',
    TW = 'TW',
    U_ZERO = 'U0',
    UK = 'UK',
    US = 'US',
    V_FIVE = 'V5',
    Z_FIVE = 'Z5',
}
