import { CoupaPurchaseLineType } from '../enums/CoupaStatus/CoupaPurchaseLineType';

export class PurchaseLineIndex {
    public purchaseId: string;
    public lineNumber: number;
    public coupaVersion?: number;
    public lineAmount: number;
    public uncommittedBalance?: number;
    public allocatedAmount?: number;
    public billingAccountCode: string;
    public billingAccountName?: string;
    public commodity?: string;
    public currencyCode?: string;
    public lineDescription?: string;
    public needByDate?: number;
    public lineType?: CoupaPurchaseLineType;
    public paymentTermCode?: string;
    public supplierId?: string;
    public lineId?: number;
    public company?: string;
    public location?: string;
    public costCenter?: string;
    public glAccount?: string;
    public productLine?: string;
    public channel?: string;
    public project?: string;
    public customEighthSegment?: string;
    public purchaseLineStatus?: string;
    public creationTime?: number;
    public lastModifiedTime?: number;
    public version?: number;

    constructor() {
        this.purchaseId = '';
        this.lineNumber = 1;
        this.lineAmount = 0.0;
        this.billingAccountCode = '';
    }

    public get isQuantityBased() {
        return this.lineType
            ? [CoupaPurchaseLineType.ORDER_QUANTITY_LINE, CoupaPurchaseLineType.REQUEST_QUANTITY_LINE].includes(
                  this.lineType
              )
            : false;
    }
}
