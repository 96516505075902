import { MspModel } from '../MspModel';

export class OrgMapping extends MspModel {
    orgName: string;
    costCenter: string;
    version: string;

    constructor() {
        super();
    }
}
