import { logger } from '../../logger';
import {
    InvoiceAllocation,
    InvoiceLine,
    Receipt,
    PurchaseLine,
    Purchase,
    PurchaseIndex,
    PurchaseLineIndex,
    ChannelAllocation,
} from '../../models';

export class DeepCopy {
    public static allocation(allocation: InvoiceAllocation): InvoiceAllocation {
        return Object.assign(new InvoiceAllocation(), allocation);
    }

    public static channelAllocation(channelAllocation: ChannelAllocation): ChannelAllocation {
        return Object.assign(new ChannelAllocation(), channelAllocation);
    }

    public static receipt(receipt: Receipt): Receipt {
        const { allocations, channelAllocations } = receipt;

        const newReceipt = Object.assign(new Receipt(), receipt);
        newReceipt.allocations = allocations?.map(DeepCopy.allocation) || [];
        newReceipt.channelAllocations = channelAllocations?.map(DeepCopy.channelAllocation) || [];

        return newReceipt;
    }

    public static purchaseLine(purchaseLine: PurchaseLine): PurchaseLine {
        const { receipts } = purchaseLine;

        const newLine = Object.assign(new PurchaseLine(), purchaseLine);
        newLine.receipts = receipts?.map(DeepCopy.receipt) || [];

        return newLine;
    }

    public static purchase(purchase: Purchase): Purchase {
        const { lines } = purchase;

        const newPurchase = Object.assign(new Purchase(), purchase);
        newPurchase.lines = lines?.map(DeepCopy.purchaseLine) || [];

        return newPurchase;
    }

    public static invoiceLine(line: InvoiceLine): InvoiceLine {
        const newLine = Object.assign(new InvoiceLine(), line);

        return newLine;
    }

    public static invoiceAllocation(invoiceAllocation: InvoiceAllocation): InvoiceAllocation {
        const newInvoiceAllocation = Object.assign(new InvoiceAllocation(), invoiceAllocation);

        return newInvoiceAllocation;
    }

    public static purchaseLineIndex(purchaseLineIndex: PurchaseLineIndex): PurchaseLineIndex {
        const newPurchaseLineIndex = Object.assign(new PurchaseLineIndex(), purchaseLineIndex);

        return newPurchaseLineIndex;
    }

    public static purchaseIndex(purchaseIndex: PurchaseIndex): PurchaseIndex {
        const { purchaseLines } = purchaseIndex;

        const newPurchaseIndex = Object.assign(new PurchaseIndex(), purchaseIndex);

        const receipts = purchaseIndex.receipts ? JSON.parse(JSON.stringify(purchaseIndex.receipts)) : [];

        const invoiceLines = purchaseIndex.invoiceLines
            ? (JSON.parse(JSON.stringify(purchaseIndex.invoiceLines)) as Map<number, InvoiceLine[]>)
            : new Map<number, InvoiceLine[]>();

        const receiptsMap = new Map<number, Receipt[]>();
        const invoiceLineMap = new Map<number, InvoiceLine[]>();

        newPurchaseIndex.purchaseLines = purchaseLines?.map(DeepCopy.purchaseLineIndex) || [];
        newPurchaseIndex.purchaseLines.forEach((purchaseLine) => {
            if (receipts && receipts[purchaseLine.lineNumber]) {
                receiptsMap.set(purchaseLine.lineNumber, receipts[purchaseLine.lineNumber]);
            }

            if (invoiceLines) {
                const invoiceLineValues = Object.entries(invoiceLines);
                const invoiceLinesArr: InvoiceLine[] = [];
                for (const [, value] of invoiceLineValues) {
                    const invoiceLine = value as InvoiceLine[];
                    invoiceLinesArr.push(...invoiceLine);
                }
                const filteredInvoiceLines = invoiceLinesArr.filter(
                    (invoiceLine) => invoiceLine.purchaseLineNumber === purchaseLine.lineNumber
                );

                logger.debug(
                    `InvoiceLines mapping updated to set for purchaseId ${
                        purchaseIndex.purchaseId
                    } purchaseLineNumber ${purchaseLine.lineNumber}  ${JSON.stringify(filteredInvoiceLines)}`
                );

                invoiceLineMap.set(purchaseLine.lineNumber, filteredInvoiceLines);
            }
        });

        newPurchaseIndex.receipts = receiptsMap;
        newPurchaseIndex.invoiceLines = invoiceLineMap;

        return newPurchaseIndex;
    }
}
