import BigNumber from 'bignumber.js';
import { ReceiptStatus, ReceiptStatusesConsumingLineAmount } from '../enums';
import { ChannelAllocation } from '../channelAllocation';
import { InvoiceAllocation } from '../invoice/InvoiceAllocation';
import { ReceiptType } from './ReceiptType';
import { CoupaReceiptType } from './CoupaReceiptType';

export class Receipt {
    public purchaseId: string;
    public teamName?: string;
    public receiptNumber: string;
    public receiptTime: number;
    public receiptId?: string;
    public receiptStatus: string;
    public orderId?: string;
    public lineNumber: number;
    public lineId?: number;
    public dueMonth?: string;
    public agedAccrualNotificationDate?: string;
    public version?: number;
    public postedAt?: number;
    public receiptAmount: number;
    public versionCategory?: string;
    public versionDescription?: string;
    public statusDescription?: string;
    public billedAmount: number;
    public creditedAmount: number;
    public isReducedByCreditMemo?: boolean;
    public creationTime: number;
    public lastModifiedTime: number;
    public deliveryInformation?: string;
    public quantity?: number;
    public pricePerUnit?: number;
    protected deleted?: string;
    public receivingType?: CoupaReceiptType;

    private confirmStatus?: number;
    private __allocations?: InvoiceAllocation[];
    private __channelAllocations?: ChannelAllocation[];

    constructor() {
        this.lineNumber = 0;
        this.receiptNumber = '';
        this.receiptAmount = 0;
        this.purchaseId = '';
        this.receiptStatus = '';
        this.billedAmount = 0;
        this.receiptTime = 0;
        this.creationTime = 0;
        this.lastModifiedTime = 0;
        this.creditedAmount = 0;
        this.quantity = 1.0;
        this.deleted = 'N';
    }

    public get isConfirmed(): boolean {
        return this.confirmStatus === 1;
    }

    public set isConfirmed(value: boolean) {
        this.confirmStatus = value ? 1 : 0;
    }

    public static from(obj: Partial<Receipt>): Receipt {
        return Object.assign(new Receipt(), obj);
    }

    public get allocations(): InvoiceAllocation[] {
        return this.__allocations || [];
    }

    public set allocations(allocations: InvoiceAllocation[]) {
        this.__allocations = allocations;
    }

    public get channelAllocations(): ChannelAllocation[] {
        return this.__channelAllocations || [];
    }

    public set channelAllocations(allocations: ChannelAllocation[]) {
        this.__channelAllocations = allocations;
    }

    public get unBilled(): number {
        const coalescedReceiptAmount = isNaN(this.receiptAmount) ? 0 : this.receiptAmount;

        const unBilledAmount = new BigNumber(coalescedReceiptAmount || 0).minus(this.billedAmount).toNumber();

        return unBilledAmount;
    }

    public void(version?: number): void {
        const _version = version ?? new Date().getTime();

        this.isConfirmed = false;
        this.version = _version;
        this.lastModifiedTime = _version;
        this.receiptStatus = ReceiptStatus.PENDING_VOID;
    }

    public clone(): Receipt {
        const clone = Receipt.from(this);

        clone.isConfirmed = false;
        clone.receiptId = undefined;
        clone.receiptStatus = ReceiptStatus.PENDING_POST;

        return clone;
    }

    public setDeliveryInformation(userAlias: string): void {
        const creationDate = new Date(this.creationTime).toISOString();
        this.deliveryInformation = `Ack By ${userAlias} on ${creationDate}`;
    }

    public deleteAllocations(): void {
        delete this.__allocations;
        delete this.__channelAllocations;
    }

    public resetReceiptConfirmation() {
        this.isConfirmed = false;

        if (this.receiptStatus === ReceiptStatus.POSTED) {
            this.receiptStatus = ReceiptStatus.PENDING_VOID;
        }

        if ([ReceiptStatus.PENDING_POST, ReceiptStatus.POST_FAILURE].includes(this.receiptStatus as ReceiptStatus)) {
            this.receiptStatus = ReceiptStatus.VOIDED;
        }
    }

    public getReceiptType(): ReceiptType {
        const currentTime = Date.now();
        const currentYear = new Date(currentTime).getUTCFullYear();
        const currentMonth = new Date(currentTime).getUTCMonth();

        const receiptYear = new Date(this.receiptTime).getUTCFullYear();
        const receiptMonth = new Date(this.receiptTime).getUTCMonth();

        if (receiptYear === currentYear && receiptMonth === currentMonth) return ReceiptType.CURRENT_MONTH_RECEIPT;

        if (receiptYear > currentYear || (receiptYear === currentYear && receiptMonth > currentMonth)) {
            return ReceiptType.FUTURE_MONTH_RECEIPT;
        }

        return ReceiptType.PAST_MONTH_RECEIPT;
    }

    public get requiresConfirmation() {
        return (
            [ReceiptType.CURRENT_MONTH_RECEIPT, ReceiptType.PAST_MONTH_RECEIPT].includes(this.getReceiptType()) &&
            !this.isConfirmed
        );
    }

    public get isDeleted(): boolean {
        return this.deleted === 'Y';
    }

    public set isDeleted(value: boolean) {
        this.deleted = value ? 'Y' : 'N';
    }

    public get consumesLineAmount() {
        return ReceiptStatusesConsumingLineAmount.includes(this.receiptStatus as ReceiptStatus);
    }
}
