import { TaskAction } from '.';
import { MspModel } from '../MspModel';
import { TaskComment } from './TaskComment';
import { TaskStatus } from './TaskStatus';
import { DateGenerator } from '../../core/class';
import { defaultEmailDelay } from '../../constants';

export class MspTask extends MspModel {
    public associatedEntity: string;
    public entityId: string;
    public assignedTo: string;
    public assignedToEmail: string;
    public requestedBy: string;
    public action: TaskAction;
    public status: TaskStatus;
    public notificationDue: number;
    public comments: TaskComment[];
    public nextNotificationDate: number;
    public forecastIds: string[];
    public waitToken?: string;
    public context?: unknown;

    constructor() {
        super();
        this.associatedEntity = '';
        this.entityId = '';
        this.assignedTo = '';
        this.assignedToEmail = '';
        this.requestedBy = '';
        this.action = TaskAction.ASSIGN_BUDGET_OWNERS;
        this.status = TaskStatus.PENDING;
        this.notificationDue = Date.now();
        this.nextNotificationDate = Date.now() + DateGenerator.convertDaystoMilliseconds(defaultEmailDelay);
        this.forecastIds = [];
        this.comments = [];
    }
}
