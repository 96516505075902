class MspDateGenerator {
    private readonly _date: Date;
    private readonly year: number;
    private readonly months: string[];
    private readonly getMonthName: (date: Date) => string;
    private readonly digitsInMilliseconds: number;
    private readonly digitsInSeconds: number;

    constructor() {
        this._date = new Date();
        this.year = this.date.getUTCFullYear();
        this.months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        this.getMonthName = new Intl.DateTimeFormat('en-US', { month: 'short', timeZone: 'UTC' }).format;
        this.digitsInMilliseconds = 13;
        this.digitsInSeconds = 10;
    }

    private get date(): Date {
        return this._date;
    }

    public getCurrentDate() {
        const month = this.getMonthName(this.date);
        return `${month} ${this.year}`;
    }

    public getMonthYear(receiptTime: number) {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', timeZone: 'UTC' };
        const monthYear = new Intl.DateTimeFormat('en', options).format(receiptTime);
        return monthYear;
    }

    public getDayOfTheMonth() {
        return this.date.getDate();
    }

    public getDates(value?: string) {
        const years = [this.year - 1, this.year, this.year + 1];

        const shortDates = Array<string>();

        years.forEach((val) => {
            this.months.forEach((mon) => {
                const date = value ? `${mon} ${val} (${value})` : `${mon} ${val}`;
                shortDates.push(date);
            });
        });

        return shortDates;
    }

    public getMonths(startTime: number, endTime: number) {
        const normalizedStartTime = this.normalizeToMilliseconds(startTime) || Date.UTC(this.year - 2, 0, 1);
        const normalizedEndTime = this.normalizeToMilliseconds(endTime) || Date.UTC(this.year + 1, 11, 1);

        const startDate = new Date(normalizedStartTime);
        const endDate = new Date(normalizedEndTime);

        const startYear = startDate.getUTCFullYear();
        const endYear = endDate.getUTCFullYear();

        const startMonth = startDate.getUTCMonth();
        const endMonth = endDate.getUTCMonth();

        const shortDates = Array<string>();

        for (let year = startYear; year <= endYear; year++) {
            const yearStartMonth = year === startYear ? startMonth : 0;
            const yearEndMonth = year === endYear ? endMonth : 11;

            for (let month = yearStartMonth; month <= yearEndMonth; month++) {
                const monthString = this.months[month];
                shortDates.push(`${monthString} ${year}`);
            }
        }

        return shortDates;
    }

    public getPastMonthRange(currentTime?: number) {
        const date = this.getUTCDate(currentTime);

        const currentMonth = date.month;
        const year = date.year;

        const { pastMonth, pastYear } = this.getPastMonthAndYear(currentMonth, year);

        const startTime = Date.UTC(pastYear, pastMonth, 1);
        const endTime = Date.UTC(pastYear, pastMonth + 1, 0, 23, 59, 59);

        return { startTime, endTime };
    }

    public getCutoffRange(cutOffDate: number, currentTime?: number) {
        const { day, month, year } = this.getUTCDate(currentTime);

        const { pastMonth, pastYear } = this.getPastMonthAndYear(month, year);

        const startMonth = day < cutOffDate ? pastMonth : month;
        const startYear = day < cutOffDate ? pastYear : year;

        const startTime = Date.UTC(startYear, startMonth, cutOffDate);
        const endTime = Date.UTC(year, month, day, 23, 59, 59);

        return { startTime, endTime };
    }

    public isMonthEnd() {
        return this.getDayOfTheMonth() >= 23;
    }

    public parsePolarisDate(date: string) {
        const monthOffset = 1;
        const [year, month, day] = date.split('-').map((s) => Number(s));

        const localDate = new Date(year, month - monthOffset, day, 0, 0, 0, 0);

        return localDate;
    }

    public parseCurrentTime(currentTime: number) {
        const localDate = new Date(currentTime);

        localDate.setUTCDate(localDate.getDate());
        localDate.setUTCFullYear(localDate.getFullYear());
        localDate.setUTCMonth(localDate.getMonth());

        localDate.setUTCHours(0);
        localDate.setUTCMinutes(0);
        localDate.setUTCSeconds(0);
        localDate.setUTCMilliseconds(0);

        return localDate;
    }

    private normalizeToMilliseconds(time: number) {
        const digitsInTime = time.toString().length;

        if (digitsInTime < this.digitsInSeconds) {
            return undefined;
        }

        if (digitsInTime < this.digitsInMilliseconds) {
            const remaining = this.digitsInMilliseconds - digitsInTime;
            const multiples = Math.pow(10, remaining);

            return time * multiples;
        }

        return time;
    }

    private getUTCDate(currentTime?: number) {
        const date = currentTime ? new Date(currentTime) : this.date;

        const day = date.getUTCDate();
        const month = date.getUTCMonth();
        const year = date.getUTCFullYear();

        return { day, month, year };
    }

    private getPastMonthAndYear(currentMonth: number, year: number) {
        const offsetMonth = currentMonth - 1;

        const pastMonth = offsetMonth < 0 ? 11 : offsetMonth;
        const pastYear = offsetMonth < 0 ? year - 1 : year;

        return { pastMonth, pastYear };
    }

    public isLastDayOfMonth(currentTime: number) {
        const date = this.getUTCDate(currentTime);
        const currentMonth = date.month;
        const year = date.year;

        const lastDateTime = Date.UTC(year, currentMonth + 1, 0, 23, 59, 59);
        const lastDate = this.getUTCDate(lastDateTime);

        return date.day === lastDate.day;
    }

    public convertDaystoMilliseconds(days: number): number {
        return days * 24 * 60 * 60 * 1000;
    }
}

export const DateGenerator = new MspDateGenerator();
