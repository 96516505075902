export class Thread {
    purchaseId: string;
    threadId: string;
    creatorLogin: string;
    creatorFirstName?: string;
    creatorLastName?: string;
    creatorTitle?: string;
    creationTime: number;
    lastModifiedTime: number;
    firstMessageContent: string;

    constructor() {
        this.purchaseId = '';
        this.threadId = '';
        this.creatorLogin = '';
        this.creatorFirstName = '';
        this.creatorLastName = '';
        this.creatorTitle = '';
        this.creationTime = Date.now();
        this.lastModifiedTime = Date.now();
        this.firstMessageContent = '';
    }
}
