export enum VersionType {
    FIRST_EDIT = 'FIRST_EDIT',
    LINE_AMOUNT_CHANGE = 'LINE_AMOUNT_CHANGE',
    LINE_ADDED = 'LINE_ADDED',
    LINE_DELETED = 'LINE_DELETED',
    CONFIRM_PROCESS = 'CONFIRM_PROCESS',
    SPEND_EDIT = 'SPEND_EDIT',
    INVOICE_ADDED = 'INVOICE_ADDED',
    INVOICE_MATCHED = 'INVOICE_MATCHED',
    INVOICE_CANCELLED = 'INVOICE_CANCELLED',
    BIG_OBJECTIVE_UPDATE = 'BIG_OBJECTIVE_UPDATE',
    PO_SOFT_CLOSED = 'PO_SOFT_CLOSED',
    BULK_EDIT_SPEND = 'BULK_EDIT_SPEND',
    CREDIT_MEMO_REDUCED = 'CREDIT_MEMO_REDUCED',
    CREDIT_MEMO_CANCELLED = 'CREDIT_MEMO_CANCELLED',
    ONBOARD_PURCHASE = 'ONBOARD_PURCHASE',
}
