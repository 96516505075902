import { ReportsDestination } from '../interfaces/ReportsDestination';

export class PurchaseFilter {
    public filterId: string;
    public teamName: string;
    public company?: string;
    public location?: string;
    public costCenter?: string;
    public glAccount?: string;
    public productLine?: string;
    public channel?: string;
    public project?: string;
    public customEighthSegment?: string;
    public superUserTeamId?: string;
    public poReportsDest?: ReportsDestination;
    public channelReportsDest?: ReportsDestination;
    public creationTime: number;
    protected onboarded: string;

    constructor() {
        this.filterId = '';
        this.teamName = '';
        this.onboarded = 'N';
    }

    public get isOnboarded(): boolean {
        return this.onboarded === 'Y';
    }

    public set isOnboarded(value: boolean) {
        this.onboarded = value ? 'Y' : 'N';
    }

    public static from(obj: unknown): PurchaseFilter {
        return Object.assign(new PurchaseFilter(), obj);
    }
}
